import {
  SegmentationIds,
  SEGMENTS_RAW_FILTER_MAP,
} from 'constants/segmentation';

const SubtitleMap = {
  [SegmentationIds.Intolerance]: 'Speziell für Allergiker',
  [SegmentationIds.Digestion]: 'Ideal bei sensibler Verdauung',
  [SegmentationIds.PickyEater]: 'Gut für wählerische Esser',
  [SegmentationIds.Welpe]: 'Für Welpen (0-1 Jahre)',
  [SegmentationIds.DogSenior]: 'Geeignet für Senioren ( 8+)',
  [SegmentationIds.SkinFur]: 'Unterstützt Haut & Fell',
  [SegmentationIds.Overweight]: 'Ideal bei Übergewicht',
  [SegmentationIds.Bulldog]: 'Ideal für Bulldoggen',
  [SegmentationIds.Chihuahua]: 'Ideal für Chihuahuas',
  [SegmentationIds.Dachshund]: 'Ideal für Dackel',
  [SegmentationIds.Herdingdog]: 'Ideal für Hütehunde',
  [SegmentationIds.Havanese]: 'Ideal für Maltese & Havaneser',
  [SegmentationIds.Poodle]: 'Ideal für Pudel',
  [SegmentationIds.Retriever]: 'Ideal für Retriever-Rassen',
  [SegmentationIds.ShepherdDog]: 'Ideal für Schäferhunde',
  [SegmentationIds.Spitz]: 'Ideal für alle Spitz Rassen',
  [SegmentationIds.Terrier]: 'Ideal für Terrier Rassen',
  [SegmentationIds.CatPickyEater]:
    'Ideal für Katzen mit wählerischem Essverhalten',
  [SegmentationIds.CatDigestion]: 'Ideal für Katzen mit Verdauungsproblemen',
  [SegmentationIds.CatOverweight]: 'Ideal für Katzen mit Übergewicht',
  [SegmentationIds.Sterilized]: 'Ideal für sterilizierte Katzen',
  [SegmentationIds.Outdoor]: 'Ideal für Freigänger',
  [SegmentationIds.Kitten]: 'Ideal für Kitten (2-12 Monate)',
  [SegmentationIds.CatSenior]: 'Ideal für Senioren (10+ Jahre)',
};

/**
 * Function to get getPersonalizedSubtitle for old collection product
 */
const getPersonalizedSubtitle = ({
  tags,
  segmentation,
}: {
  tags: Array<string>;
  segmentation: SegmentationIds;
}): string | undefined => {
  // First try exact match
  const exactEntry = Object.entries(SEGMENTS_RAW_FILTER_MAP).find(
    ([, value]) => value === segmentation
  );

  // If no exact match, try array match
  const arrayEntry = !exactEntry
    ? Object.entries(SEGMENTS_RAW_FILTER_MAP).find(
        ([, value]) => Array.isArray(value) && value.includes(segmentation)
      )
    : null;

  const entry = exactEntry || arrayEntry;

  if (!entry) return undefined;

  const segmentTag = tags.find((tag) => tag === entry[0]);

  if (!segmentTag) return undefined;

  return SubtitleMap[segmentation] as string;
};

export default getPersonalizedSubtitle;
