import laggy from 'api/middleware/laggy';
import { CollectionResponse } from 'api/product';
import {
  fetchContentfulByHandle,
  fetchContentfulProductPage,
  FetchContentRes,
} from 'connectors/contentful';
import { Entry } from 'contentful';
import { useSegmentationContext } from 'contexts/segmentation';
import NotFound from 'pages/404';
import React, { Context as ContextType, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import useSWR, { unstable_serialize } from 'swr';
import { IProductPageFields, IProductPagesFields } from '../../../global';

interface ProviderProps {
  fallbackData: any;
  shopifyData: FetchContentRes;
  handle: string;
  isProductPage?: boolean;
  query?: any;
}

export type DefaultContentfulType = Entry<
  IProductPageFields | IProductPagesFields
>;

interface MyContext<T = DefaultContentfulType> {
  shopifyCollectionMap: {
    [key: string]: CollectionResponse;
  };
  shopifyProductDetailMap: FetchContentRes['productDetailMap'];
  data: T;
}

const ContentfulContext = React.createContext<MyContext>({} as MyContext);

const ContentfulProvider: React.FC<React.PropsWithChildren<ProviderProps>> = ({
  children,
  fallbackData,
  isProductPage = false,
  shopifyData,
  handle,
  query = null,
}) => {
  const { pushSegmentation } = useSegmentationContext();
  const q = React.useMemo(() => {
    return query;
  }, [query]);

  // Pre-rendering with Default Data
  // See https://swr.vercel.app/docs/with-nextjs#pre-rendering-with-default-data
  const { error, data } = useSWR(
    q === null ? handle : [handle, q],
    isProductPage
      ? fetchContentfulProductPage
      : (handle, q2) => {
          return fetchContentfulByHandle(handle, q2);
        },
    {
      // https://github.com/vercel/swr/issues/1520#issuecomment-932339824
      fallback: {
        [unstable_serialize(q === null ? handle : [handle, q])]: fallbackData,
        use: [laggy],
      },
    }
  );

  const shopifyCollectionMap = React.useMemo(() => {
    const shopifyCollectionData = shopifyData['collectionMap'];
    return shopifyCollectionData;
  }, [shopifyData]);

  const shopifyProductDetailMap = React.useMemo(() => {
    return shopifyData['productDetailMap'];
  }, [shopifyData]);

  // If a segment is assigned to a page in Contentful, this will be pushed to the segmentation context
  useEffect(() => {
    if (fallbackData.fields?.assignSegmentation) {
      pushSegmentation({
        segmentation: fallbackData.fields.assignSegmentation,
      });
    }
  }, [fallbackData.fields, pushSegmentation]);

  return (
    <ContentfulContext.Provider
      value={{
        shopifyCollectionMap,
        shopifyProductDetailMap,
        data: data as DefaultContentfulType,
      }}
    >
      {!error && !data ? (
        <div className="text-center">
          <FormattedMessage id="common:loading" />
        </div>
      ) : (
        <>{data ? children : <NotFound />}</>
      )}
    </ContentfulContext.Provider>
  );
};

function useContentful<T = DefaultContentfulType>(): MyContext<T> {
  return React.useContext<MyContext<T>>(
    ContentfulContext as unknown as ContextType<MyContext<T>>
  );
}
export { ContentfulProvider, useContentful };
