import { Reference, VariantRef } from '@ninetailed/experience.js';
import {
  NinetailedAnalyticsPlugin,
  SanitizedElementSeenPayload,
  Template,
} from '@ninetailed/experience.js-plugin-analytics';
import { template } from '@ninetailed/experience.js-shared';
import {
  AudienceEntryLike,
  AudienceMapper,
  BaselineWithExperiencesEntry,
  Entry,
  ExperienceEntryLike,
  ExperienceMapper,
} from '@ninetailed/experience.js-utils-contentful';
import { contentfulClient } from 'connectors/contentful';
import { ShopId } from 'constants/shop';
import cookie from 'cookie';
import { CustomerSubscriptionStatus } from 'interfaces/customer';
import { sendData } from '../connectors/kinesis';

type OurTraits = Partial<{
  ordersCount: number;
  createdAt: number;
  subscriptionStatus: CustomerSubscriptionStatus;
  isUserLoggedIn: boolean;
  /** PetType. Should be used to differentiate contents for dogs or cats  */
  petType: 'dogs' | 'cats';
  /** shopId. Should be used to differentiate audience in each shops  */
  shopId: ShopId;
  /** Snacl likelihood */
  snackLikelihood: number;
  /** Supplement likelihood */
  supplementLikelihood: number;
  /**
   * Segmentation
   * @see : https://petsdeli.atlassian.net/wiki/spaces/DOKUMENTAT/pages/1974042625/Segmentation
   * */
  /** Last pushed segmentation  */
  lastSegment: string;
  /** petProfile, array of segmentationId */
  petProfile: Array<string>;
  /** allSegments, array of segmentationId */
  allSegments: Array<string>;
}>;

type NinetailedGoogleTagmanagerPluginOptions = {
  actionTemplate?: string;
  labelTemplate?: string;

  template?: Template;
};

const TEMPLATE_OPTIONS = {
  interpolate: /{{([\s\S]+?)}}/g,
};

/**
 * Assert traits property as our traits because Ninetailed traits is typed as JSON type.
 */
export function isTraits(val: any): val is OurTraits {
  return typeof val === 'object' && typeof val['ordersCount'] === 'number';
}

export type MyVariant<Fields = unknown> = Omit<
  Fields,
  'nt_variants' | 'nt_audience' | 'nt_experiences'
> &
  Reference;

export function isVariantRef(val: any): val is VariantRef {
  return typeof val === 'object' && val['hidden'] === 'boolean';
}

type SingularBlock = Entry | BaselineWithExperiencesEntry;

const hasExperiences = (
  entry: SingularBlock
): entry is BaselineWithExperiencesEntry => {
  return (
    (entry as BaselineWithExperiencesEntry).fields.nt_experiences !== undefined
  );
};

/**
 * @TODO : add comment
 */
export const parseExperiences = ({ entry }: { entry?: SingularBlock }) => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const _hasEx = hasExperiences(entry);

    return _hasEx
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        entry.fields.nt_experiences
          .filter((experience) =>
            ExperienceMapper.isExperienceEntry(experience)
          )
          .map((experience) =>
            ExperienceMapper.mapCustomExperience(experience, (variant) => ({
              ...variant.fields,
              id: variant.sys.id,
            }))
          )
      : [];
  } catch (error) {
    return [];
  }
};

export const getAllExperiences = async () => {
  const entries = await contentfulClient.getEntries({
    content_type: 'nt_experience',
  });
  return (entries.items as ExperienceEntryLike[])
    .filter(ExperienceMapper.isExperienceEntry)
    .map(ExperienceMapper.mapExperience);
};

export const getAllAudiences = async () => {
  const entries = await contentfulClient.getEntries({
    content_type: 'nt_audience',
  });
  return (entries.items as AudienceEntryLike[])
    .filter(AudienceMapper.isAudienceEntry)
    .map(AudienceMapper.mapAudience);
};

/**
 * Custom Google Tag Manager plugin for Ninetailed
 * Reference: https://github.com/ninetailed-inc/experience.js/blob/main/packages/plugins/google-tagmanager/src/NinetailedGoogleTagmanagerPlugin.ts
 */
interface CachedEvent {
  payload: Record<string, string>;
  attempts: number;
  timestamp: number;
}

interface DebugInfo {
  cacheStatus: {
    size: number;
    events: Array<[string, CachedEvent]>;
  };
  failedEvents: {
    size: number;
    events: Array<[string, CachedEvent]>;
  };
  isGAReady: boolean;
  eventCacheSize: number;
  retryInterval: number;
  maxRetries: number;
}

let gtmPluginInstance: NinetailedGoogleTagmanagerPlugin | null = null;

/**
 * Returns a singleton instance of the Google Tag Manager plugin.
 * This ensures we always work with the same plugin instance across the application,
 * preventing issues with multiple instances tracking events differently or losing
 * cached/failed events during SSR hydration or component re-renders.
 * The singleton pattern is especially important for maintaining consistent event tracking
 * and retry mechanisms for failed GTM events.
 *
 * @returns {NinetailedGoogleTagmanagerPlugin} The singleton GTM plugin instance
 */
export const getGtmPlugin = (): NinetailedGoogleTagmanagerPlugin => {
  if (!gtmPluginInstance) {
    gtmPluginInstance = new NinetailedGoogleTagmanagerPlugin();

    // Expose in debug registry if needed
    if (typeof window !== 'undefined') {
      (window as any).__NT_DEBUG = (window as any).__NT_DEBUG || {};
      (window as any).__NT_DEBUG.pluginRegistry =
        (window as any).__NT_DEBUG.pluginRegistry || new Map();
      (window as any).__NT_DEBUG.pluginRegistry.set('gtm', gtmPluginInstance);
    }
  }
  return gtmPluginInstance;
};

export class NinetailedGoogleTagmanagerPlugin extends NinetailedAnalyticsPlugin {
  public name = 'ninetailed:googleTagmanager';
  private eventCache: Set<string>;
  private retryCache: Map<string, CachedEvent> = new Map();
  private failedEventsCache = new Map<string, CachedEvent>();
  private retryInterval = 2000;
  private maxRetries = 5;
  private retryTimeoutId: NodeJS.Timeout | null = null;

  constructor(
    private readonly options: NinetailedGoogleTagmanagerPluginOptions = {}
  ) {
    super({
      ...options.template,
      event: 'nt_experience',
      ninetailed_variant: '{{selectedVariantSelector}}',
      ninetailed_experience: '{{experience.id}}',
      ninetailed_experience_name: '{{experience.name}}',
      ninetailed_audience: '{{audience.id}}',
      ninetailed_component: '{{selectedVariant.id}}',
      ninetailed_experience_type: '{{experience.type}}',
      ninetailed_profile_id: '{{profile.id}}',
    });
    this.eventCache = new Set();

    if (typeof window !== 'undefined') {
      // Expose debug namespace if it doesn't exist
      (window as any).__NT_DEBUG = (window as any).__NT_DEBUG || {};

      // Create a registry if it doesn't exist
      (window as any).__NT_DEBUG.pluginRegistry =
        (window as any).__NT_DEBUG.pluginRegistry || new Map();

      // Register this plugin instance
      (window as any).__NT_DEBUG.pluginRegistry.set('gtm', this);
    }
  }

  // Debug methods for QA
  public getRetryCacheStatus(): {
    size: number;
    events: Array<[string, CachedEvent]>;
  } {
    return {
      size: this.retryCache.size,
      events: Array.from(this.retryCache.entries()),
    };
  }

  // Get GTM plugin instance
  // window.__NT_DEBUG.pluginRegistry.get('gtm')

  // Get cache status
  // window.__NT_DEBUG.pluginRegistry.get('gtm').getDebugInfo()
  public getDebugInfo = (): DebugInfo => ({
    cacheStatus: this.getRetryCacheStatus(),
    failedEvents: {
      size: this.failedEventsCache.size,
      events: Array.from(this.failedEventsCache.entries()),
    },
    isGAReady: this.isGAReady(),
    eventCacheSize: this.eventCache.size,
    retryInterval: this.retryInterval,
    maxRetries: this.maxRetries,
  });
  public initialize = (): void => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
    }
  };

  private generateEventKey = (payload: Record<string, string>): string =>
    `${payload.ninetailed_experience}_${payload.ninetailed_component}`;

  private isGAReady(): boolean {
    return (
      typeof window !== 'undefined' &&
      Array.isArray(window.dataLayer) &&
      // @ts-ignore
      typeof window.gtag === 'function' &&
      document.cookie.includes('_ga=')
    );
  }

  private logDebug(...args: any[]): void {
    console.log('[NinetailedGTM]', ...args);
  }

  private pushToDataLayer(
    payload: Record<string, string>,
    eventKey: string
  ): boolean {
    if (!this.isGAReady()) {
      this.logDebug('GA not ready, caching event', eventKey);
      return false;
    }

    try {
      window.dataLayer?.push(payload);
      this.eventCache.add(eventKey);
      this.logDebug('Event pushed successfully', eventKey);
      return true;
    } catch (error) {
      this.logDebug('Error pushing event', error);
      return false;
    }
  }

  private scheduleRetry(): void {
    if (this.retryTimeoutId) return;

    this.retryTimeoutId = setTimeout(async () => {
      this.retryTimeoutId = null;
      await this.processRetryCacheEvents();
    }, this.retryInterval);
  }

  private async processRetryCacheEvents(): Promise<void> {
    this.logDebug('Processing retry cache', {
      size: this.retryCache.size,
      events: Array.from(this.retryCache.entries()),
    });

    for (const [eventKey, cachedEvent] of this.retryCache.entries()) {
      if (cachedEvent.attempts >= this.maxRetries) {
        this.logDebug('Max retries reached for event', eventKey, cachedEvent);

        // Store the failed event before removing it from the retry cache
        this.failedEventsCache.set(eventKey, cachedEvent);
        this.retryCache.delete(eventKey);
        continue;
      }

      const success = this.pushToDataLayer(cachedEvent.payload, eventKey);
      if (success) {
        this.retryCache.delete(eventKey);
      } else {
        this.retryCache.set(eventKey, {
          ...cachedEvent,
          attempts: cachedEvent.attempts + 1,
        });
      }
    }

    if (this.retryCache.size > 0) {
      this.scheduleRetry();
    }
  }

  protected async onTrackExperience(
    properties: SanitizedElementSeenPayload,
    hasSeenExperienceEventPayload: Record<string, string>
  ): Promise<void> {
    const profile = window.ninetailed?.profile;
    // Don't track personalization experiences
    if (
      hasSeenExperienceEventPayload.ninetailed_experience_type ===
      'nt_personalization'
    )
      return;

    // Add profile id to the payload
    if (profile) {
      hasSeenExperienceEventPayload.ninetailed_profile_id = profile.id;
    }

    // In order to avoid duplicate events, we cache the event key
    const eventKey = this.generateEventKey(hasSeenExperienceEventPayload);
    if (this.eventCache.has(eventKey)) return;

    const success = await this.pushToDataLayer(
      hasSeenExperienceEventPayload,
      eventKey
    );
    if (!success) {
      this.retryCache.set(eventKey, {
        payload: hasSeenExperienceEventPayload,
        attempts: 1,
        timestamp: Date.now(),
      });
      this.scheduleRetry();
    }
  }

  protected async onTrackComponent(properties): Promise<void> {
    const { variant, audience, isPersonalized } = properties;

    const action = template(
      this.options.actionTemplate || 'Has Seen Experience',
      { component: variant, audience },
      TEMPLATE_OPTIONS.interpolate
    );

    const label = template(
      this.options.labelTemplate ||
        '{{ baselineOrVariant }}:{{ component.id }}',
      {
        component: variant,
        audience,
        baselineOrVariant: isPersonalized ? 'Variant' : 'Baseline',
      },
      TEMPLATE_OPTIONS.interpolate
    );

    window.dataLayer?.push({
      event: action,
      properties: {
        category: 'Ninetailed',
        label,
        nonInteraction: true,
      },
    });
  }
}

type InternalTrackingPluginOptions = {
  actionTemplate?: string;
  labelTemplate?: string;
  template?: Template;
};

/**
 * Custom plugin to send tracking information to AWS Kinesis.
 */
export class InternalTrackingPlugin extends NinetailedAnalyticsPlugin {
  public name = 'internal:tracking';
  private eventCache: Set<string>;
  private sessionId: string | undefined;
  private releaseVersion: string | undefined;
  private userAgent: string | undefined;
  private cookieConsent: string | undefined;
  private eventQueue: Array<() => void> = [];
  private maxWaitTime = 5000;
  private checkInterval = 500;
  private elapsedTime = 0;

  constructor(private readonly options: InternalTrackingPluginOptions = {}) {
    super({
      ...options.template,
      event: 'nt_experience',
      ninetailed_variant: '{{selectedVariantSelector}}',
      ninetailed_experience: '{{experience.id}}',
      ninetailed_experience_name: '{{experience.name}}',
      ninetailed_audience: '{{audience.id}}',
      ninetailed_component: '{{selectedVariant.id}}',
      ninetailed_experience_type: '{{experience.type}}',
      ninetailed_profile_id: '{{profile.id}}',
    });
    this.eventCache = new Set();
    this.initialize();
  }

  private initialize = (): void => {
    if (typeof navigator !== 'undefined') {
      this.userAgent = navigator.userAgent;
    }
    this.initializeSessionData();
  };

  private initializeSessionData = (): void => {
    this.checkSessionId();
  };

  private checkSessionId = (): void => {
    if (typeof document !== 'undefined') {
      const cookies = cookie.parse(document.cookie);
      this.sessionId = cookies._ga;
      this.releaseVersion = cookies.pd_rv;
      this.cookieConsent = cookies.CookieConsent;
    }

    if (!this.sessionId && this.elapsedTime < this.maxWaitTime) {
      setTimeout(() => {
        this.elapsedTime += this.checkInterval;
        this.checkSessionId();
      }, this.checkInterval);
    } else {
      this.processQueuedEvents();
    }
  };

  private queueEvent = (eventFunction: () => void): void => {
    this.eventQueue.push(eventFunction);
  };

  private processQueuedEvents = (): void => {
    this.eventQueue.forEach((eventFunction) => eventFunction());
    this.eventQueue = [];
  };

  private isGoogleAnalyticsLoaded = (): string => {
    return (
      typeof window !== 'undefined' &&
      Array.isArray(window.dataLayer) &&
      // @ts-ignore
      typeof window.gtag === 'function'
    ).toString();
  };

  private isCookieBotLoaded = (): string => {
    if (typeof window !== 'undefined' && Array.isArray(window.dataLayer)) {
      for (const item of window.dataLayer) {
        if (
          // @ts-ignore
          item.event === 'cookie_consent_show' &&
          // @ts-ignore
          typeof item.cookiebot_displayed !== 'undefined'
        ) {
          // @ts-ignore
          return item.cookiebot_displayed.toString();
        }
      }
    }
    return 'false';
  };

  /**
   * Checks various privacy settings and returns the reasons why the privacy shield is enabled.
   * If there are no reasons, returns 'No'.
   */
  private isPrivacyShieldEnabled = (): string => {
    if (typeof window === 'undefined') return 'Window is undefined';

    const reasons: string[] = [];

    try {
      // Check if third-party cookies are blocked by checking GA cookie
      if (document.cookie.indexOf('_ga=') === -1) {
        reasons.push('Third-party cookies are blocked');
      }

      // Check if localStorage is blocked
      try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
      } catch (e) {
        reasons.push('localStorage is blocked');
      }

      // Check if cookies are generally blocked
      if (!navigator.cookieEnabled) {
        reasons.push('Cookies are disabled in the browser');
      }

      // Check Do Not Track setting
      // @ts-ignore
      if (navigator.doNotTrack === '1' || window.doNotTrack === '1') {
        reasons.push('Do Not Track is enabled');
      }

      // Check if IndexedDB is blocked (common in private modes)
      try {
        if (!window.indexedDB) {
          reasons.push('IndexedDB is blocked');
        }
      } catch (e) {
        reasons.push('IndexedDB is blocked');
      }

      // Check if Google Analytics is blocked
      if (this.isGoogleAnalyticsLoaded() === 'false') {
        reasons.push('Google Analytics is blocked');
      }

      return reasons.length === 0 ? 'No' : reasons.join(', ');
    } catch (e) {
      return 'Error occurred while checking privacy shield status';
    }
  };

  private generateEventKey = (payload: Record<string, string>): string =>
    `${payload.ninetailed_experience}_${payload.ninetailed_component}`;

  private generatePartitionKey = (key: string): string =>
    `${this.sessionId}-${key}`;

  protected onTrackExperience = async (
    properties: SanitizedElementSeenPayload,
    hasSeenExperienceEventPayload: Record<string, string>
  ): Promise<void> => {
    // In order to avoid duplicate events, we cache the event key
    const eventKey = this.generateEventKey(hasSeenExperienceEventPayload);
    if (!this.eventCache.has(eventKey)) {
      this.eventCache.add(eventKey);

      const sendEvent = (): void => {
        const profile = window.ninetailed?.profile;
        const timestamp = new Date().toISOString();

        // Add profile id and additional fields to the payload
        if (profile) {
          hasSeenExperienceEventPayload.ninetailed_profile_id = profile.id;
        }
        if (this.sessionId) {
          hasSeenExperienceEventPayload.session_id = this.sessionId;
        }
        if (this.releaseVersion) {
          hasSeenExperienceEventPayload.releaseVersion = this.releaseVersion;
        }
        if (this.cookieConsent) {
          hasSeenExperienceEventPayload.cookieConsent = this.cookieConsent;
        }
        if (this.userAgent) {
          hasSeenExperienceEventPayload.userAgent = this.userAgent;
        }
        hasSeenExperienceEventPayload;
        hasSeenExperienceEventPayload.timestamp = timestamp;
        hasSeenExperienceEventPayload.privacyShieldEnabled =
          this.isPrivacyShieldEnabled();
        hasSeenExperienceEventPayload.cookiebotLoaded =
          this.isCookieBotLoaded();
        hasSeenExperienceEventPayload.gaLoaded = this.isGoogleAnalyticsLoaded();

        sendData({
          data: new Uint8Array(
            Buffer.from(JSON.stringify(hasSeenExperienceEventPayload))
          ),
          partitionKey: this.generatePartitionKey(eventKey),
        });
      };

      if (!this.sessionId && this.elapsedTime < this.maxWaitTime) {
        this.queueEvent(sendEvent);
      } else {
        sendEvent();
      }
    }
  };

  protected onTrackComponent = async (properties): Promise<void> => {
    const { variant, audience, isPersonalized } = properties;

    const action = template(
      this.options.actionTemplate || 'Has Seen Experience',
      { component: variant, audience },
      TEMPLATE_OPTIONS.interpolate
    );

    const label = template(
      this.options.labelTemplate ||
        '{{ baselineOrVariant }}:{{ component.id }}',
      {
        component: variant,
        audience,
        baselineOrVariant: isPersonalized ? 'Variant' : 'Baseline',
      },
      TEMPLATE_OPTIONS.interpolate
    );

    const payload = {
      event: action,
      properties: {
        category: 'Ninetailed',
        label,
        nonInteraction: true,
      },
    };

    sendData({
      data: new Uint8Array(Buffer.from(JSON.stringify(payload))),
      partitionKey: this.generatePartitionKey(`${label}-${action}`),
    });
  };
}
