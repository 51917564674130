import { Handles } from 'constants/collection/handles';
import {
  CollectionFilterConfig,
  FilterConfig,
} from '/interfaces/collection-filter';

export const ALL_AVAILABLE_FILTERS: FilterConfig = {
  'nutritional-needs': {
    tags: [
      'sehr-wahlerisch',
      'verdauungsprobleme',
      'unvertraglichkeiten-allergien',
      'haut-and-fellprobleme',
      'bestimmte-erkrankung',
      'pickyeatercat',
      'digestioncat',
      'overweightcat',
      'outdoorcat',
      'sterilizedcat',
      'ubergewicht',
    ],
    compare: 'AND',
  },
  'feed-type': {
    tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
  },
  sorts: {
    tags: [
      'huhn',
      'rind',
      'ente',
      'pute',
      'kalb',
      'wild',
      'lachs',
      'pferd',
      'lamm',
      'vegan',
      'ziege',
      'kanguru',
      'hirsch',
      'thunfisch',
      'forelle',
      'wasserbuffel',
      'kaninchen',
      'straus',
      'geflugel',
      'truthahn',
    ],
  },
  desires: {
    tags: [
      'getreidefrei',
      'glutenfrei',
      'hypoallergen',
      'monoprotein',
      'low-energy',
      'high-energy',
    ],
    compare: 'AND',
  },
  age: {
    tags: ['welpe', 'adult', 'senior', 'kitten'],
  },
  sizes: {
    tags: ['klein'],
  },
  line: {
    tags: [
      'classic',
      'sensitiv',
      'mini',
      'green',
      'extra-meat',
      'limited',
      'superfood',
      'kaltgepresst',
      'softies',
      'happchen',
      'pasteten',
      'kausnacks',
      'pure-meat',
      'feinschmecker-pate',
      'saucen-menu',
      'genieser-filets',
      'filet-menu',
      'akzeptanz',
      'creme-snack',
      'soft-snacks',
      'filet-snack',
      'suppe',
      'gefriergetrocknet',
    ],
  },
  funktion: {
    tags: [
      'gelenk',
      'haut-and-fell',
      'zeckensaison',
      'denta',
      'entspannung',
      'leber',
      'verdauung',
      'erkaltung',
    ],
  },
  'snack-type': {
    tags: [
      'knochen-and-kaurollen',
      'trainingssnacks',
      'zahnpflege',
      'functional-snacks',
      'pasten-and-cremesnacks',
      'hundekekse',
      'kaustreifen-and-sticks',
      'toppers',
      'creme-snack',
      'filet-snack',
      'suppe',
      'soft-snacks',
      'sensitiv',
      'pasten',
    ],
  },
  'hero-filter': {
    tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
  },
  'health-needs': {
    tags: [
      'gelenk',
      'haut-and-fell',
      'zeckensaison',
      'denta',
      'entspannung',
      'leber',
      'verdauung',
      'erkaltung',
      'ungeziefer',
    ],
  },
};

/**
 * Config to define filter for each collection.
 */
const FILTER_CONFIG: CollectionFilterConfig = {
  'test-cdp-qa': {
    sorts: {
      tags: ['rind', 'kalb'],
    },
    line: {
      tags: ['bundle'],
    },
  },
  'fur-hunde-nassfutter': {
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
        'bestimmte-erkrankung',
      ],
      compare: 'AND',
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'ziege',
        'kanguru',
        'insekten',
        'vegetarisch-vegan',
      ],
    },
  },
  'fur-hunde-trockenfutter': {
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
      ],
      compare: 'AND',
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'insekten',
        'vegetarisch-vegan',
      ],
    },
    line: {
      tags: ['soft', 'classic', 'green', 'superfood', 'junior', 'hypocare'],
    },
  },
  'fur-hunde-nassfutter-storys': {
    sorts: {
      tags: [
        'huhn',
        'rind',
        'ente',
        'pute',
        'kalb',
        'wild',
        'lachs',
        'pferd',
        'lamm',
        'vegan',
        'ziege',
        'kanguru',
        'hirsch',
      ],
    },
    desires: {
      tags: [
        'getreidefrei',
        'glutenfrei',
        'hypoallergen',
        'monoprotein',
        'low-energy',
        'high-energy',
      ],
      compare: 'AND',
    },
    age: {
      tags: ['welpe', 'adult', 'senior'],
    },
    sizes: {
      tags: ['klein'],
    },
    line: {
      tags: [
        'classic',
        'sensitiv',
        'vet',
        'mini',
        'breed-fit',
        'green',
        'extra-meat',
        'limited',
      ],
    },
  },
  'fur-hunde-nassfutter-test': {
    'special-desires': {
      tags: [
        'fur-welpen',
        'fur-senioren',
        'getreide-glutenfrei',
        'ohne-kartoffeln',
        'fur-allergiker',
        'sensible-verdauung',
        'weniger-kalorien',
        'fur-aktive-hunde',
        'fur-kleine-rassen',
        'spezielle-erkrankungen',
      ],
      compare: 'AND',
    },
    taste: {
      tags: [
        'geflugel',
        'rind-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'ziege',
        'kanguru',
        'insekten',
        'vegetarisch-vegan',
      ],
    },
  },
  'fur-hunde-barf-frische-menus': {
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
      ],
      compare: 'AND',
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
    sorts: {
      tags: [
        'huhn',
        'rind',
        'wild',
        'pferd',
        'kanguru',
        'wasserbuffel',
        'lamm',
      ],
    },
  },
  'fur-hunde-snacks': {
    'snack-type': {
      tags: [
        'knochen-and-kaurollen',
        'trainingssnacks',
        'zahnpflege',
        'functional-snacks',
        'pasten-and-cremesnacks',
        'hundekekse',
        'kaustreifen-and-sticks',
        'toppers',
      ],
    },
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
      ],
      compare: 'AND',
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'ziege',
        'kanguru',
        'kaninchen',
        'straus',
        'schwein',
        'insekten',
        'vegetarisch-vegan',
      ],
    },
  },
  'fur-hunde-nahrungserganzung': {
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
  },
  'fur-hunde-gesundheit': {
    'health-needs': {
      tags: [
        'gelenk',
        'haut-and-fell',
        'zeckensaison',
        'denta',
        'entspannung',
        'leber',
        'verdauung',
        'erkaltung',
        'ungeziefer',
      ],
    },
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
        'bestimmte-erkrankung',
      ],
    },
    line: {
      tags: ['vet'],
    },
  },
  'fur-hunde-zubehor': {
    sorts: {
      tags: [
        'bowls-and-similars',
        'toys',
        'walking',
        'sleeping',
        'other-accessories',
      ],
    },
    funktion: {
      tags: ['digestion', 'busy', 'smart-accessories'],
    },
  },
  'fur-hunde-futter-fur-kleine-rassen': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: ['huhn', 'rind', 'ente', 'pute', 'lamm'],
    },
  },
  'fur-hunde-welpen-und-junghunde': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: [
        'geflugel',
        'rind',
        'lachs',
        'lamm',
        'ente',
        'pferd',
        'wasserbuffel',
        'kanguru',
      ],
    },
  },
  'fur-hunde-welpen-und-junghunde-lp1': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: [
        'geflugel',
        'rind',
        'lachs',
        'lamm',
        'ente',
        'pferd',
        'wasserbuffel',
        'kanguru',
      ],
    },
  },
  'fur-hunde-beliebte-produkte': {
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'ziege',
        'kanguru',
        'insekten',
        'vegetarisch-vegan',
        'schwein',
      ],
    },
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
      ],
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
    'hero-filter': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-sensitiv': {
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        // 'wild',
        'fisch',
        'lamm',
        'pferd',
        // 'insekten',
        // 'vegetarisch-vegan',
        'ziege',
        'kanguru',
        // 'straus',
        // 'kaninchen',
      ],
    },
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-haut-fellprobleme': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'rind-and-kalb', 'fisch', 'pferd', 'ziege'],
    },
  },
  'fur-hunde-haut-fellprobleme-lp': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'rind-and-kalb', 'fisch', 'pferd', 'ziege'],
    },
  },
  'fur-hunde-verdauungsprobleme': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'fisch', 'pferd', 'ziege', 'vegetarisch-vegan'],
    },
  },
  'fur-hunde-verdauungsprobleme-lp': {
    sorts: {
      tags: ['geflugel', 'fisch', 'pferd', 'ziege', 'vegetarisch-vegan'],
    },
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-verdauungsprobleme-lp1': {
    sorts: {
      tags: ['geflugel', 'fisch', 'pferd', 'ziege', 'vegetarisch-vegan'],
    },
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-verdauungsprobleme-lp2': {
    sorts: {
      tags: ['geflugel', 'fisch', 'pferd', 'ziege', 'vegetarisch-vegan'],
    },
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-sehr-wahlerisch': {
    sorts: {
      tags: ['geflugel', 'rind-and-kalb', 'lamm', 'schwein'],
    },
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-unvertraeglichkeiten-allergien': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: [
        'geflugel',
        'wild',
        'pferd',
        'ziege',
        'kanguru',
        'insekten',
        'vegetarisch-vegan',
      ],
    },
  },
  'fur-hunde-unvertraeglichkeiten-allergien-lp': {
    sorts: {
      tags: [
        'geflugel',
        'wild',
        'pferd',
        'ziege',
        'kanguru',
        'insekten',
        'vegetarisch-vegan',
      ],
    },
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-unvertraeglichkeiten-allergien-lp1': {
    sorts: {
      tags: [
        'geflugel',
        'wild',
        'pferd',
        'ziege',
        'kanguru',
        'insekten',
        'vegetarisch-vegan',
      ],
    },
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-unvertraeglichkeiten-allergien-lp2': {
    sorts: {
      tags: [
        'geflugel',
        'wild',
        'pferd',
        'ziege',
        'kanguru',
        'insekten',
        'vegetarisch-vegan',
      ],
    },
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-sehr-waehlerisch': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'rind-and-kalb', 'lamm', 'schwein'],
    },
  },
  'fur-hunde-sehr-waehlerisch-lp': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'rind-and-kalb', 'lamm', 'schwein'],
    },
  },
  'fur-hunde-sehr-waehlerisch-lp1': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'rind-and-kalb', 'lamm', 'schwein'],
    },
  },
  'fur-hunde-sehr-waehlerisch-lp2': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'rind-and-kalb', 'lamm', 'schwein'],
    },
  },

  'fur-hunde-alle-fleischsorten-lp': {
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'ziege',
        'kanguru',
        'insekten',
        'vegetarisch-vegan',
        'wasserbuffel',
        'schwein',
      ],
    },
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
        'bestimmte-erkrankung',
      ],
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
    'hero-filter': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-uebergewicht': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'rind-and-kalb', 'fisch', 'insekten'],
    },
  },
  [Handles.allProductsForDog]: {
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'ziege',
        'kanguru',
        'insekten',
        'vegetarisch-vegan',
        'wasserbuffel',
        'schwein',
      ],
    },
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
        'bestimmte-erkrankung',
      ],
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
    'hero-filter': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-ohne-zucker-lp': {
    sorts: {
      tags: ['geflugel', 'rind-and-kalb', 'wild', 'lamm', 'pferd', 'insekten'],
    },
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
      ],
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-nassfutter-lp': {
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'insekten',
        'vegetarisch-vegan',
      ],
    },
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
      ],
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
  },
  'fur-hunde-nassfutter-lp-2': {
    sorts: {
      tags: [
        'huhn',
        'rind',
        'ente',
        'pute',
        'kalb',
        'wild',
        'lachs',
        'pferd',
        'lamm',
        'vegan',
        'ziege',
        'kanguru',
        'hirsch',
      ],
    },
    desires: {
      tags: [
        'getreidefrei',
        'glutenfrei',
        'hypoallergen',
        'monoprotein',
        'low-energy',
        'high-energy',
      ],
      compare: 'AND',
    },
    age: {
      tags: ['welpe', 'adult', 'senior'],
    },
    sizes: {
      tags: ['klein'],
    },
    line: {
      tags: [
        'classic',
        'sensitiv',
        'vet',
        'mini',
        'breed-fit',
        'green',
        'extra-meat',
        'limited',
      ],
    },
  },
  'fur-hunde-barf-frische-menus-lp-2': {
    sorts: {
      tags: [
        'huhn',
        'rind',
        'wild',
        'pferd',
        'kanguru',
        'wasserbuffel',
        'hirsch',
        'lamm',
      ],
    },
    desires: {
      tags: ['getreidefrei', 'glutenfrei', 'hypoallergen', 'monoprotein'],
      compare: 'AND',
    },
    age: {
      tags: ['welpe', 'adult', 'senior'],
    },
    line: {
      tags: ['classic', 'sensitiv'],
    },
  },
  'fur-hunde-trockenfutter-lp': {
    sorts: {
      tags: [
        'huhn',
        'rind',
        'ente',
        'pute',
        'kalb',
        'wild',
        'lachs',
        'lamm',
        'thunfisch',
        'forelle',
        'hirsch',
        'pferd',
      ],
    },
    desires: {
      tags: [
        'getreidefrei',
        'glutenfrei',
        'hypoallergen',
        'monoprotein',
        'low-energy',
        'high-energy',
      ],
      compare: 'AND',
    },
    age: {
      tags: ['welpe', 'adult', 'senior'],
    },
    sizes: {
      tags: ['klein'],
    },
    line: {
      tags: [
        'classic',
        'sensitiv',
        'mini',
        'superfood',
        'kaltgepresst',
        'green',
      ],
    },
  },
  'fur-hunde-barf-frische-menus-lp': {
    sorts: {
      tags: [
        'huhn',
        'rind',
        'pferd',
        'kanguru',
        'wasserbuffel',
        'hirsch',
        'lamm',
      ],
    },
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
      ],
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre'],
    },
  },
  'fur-hunde-snacks-lp': {
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'ziege',
        'kanguru',
        'kaninchen',
        'straus',
        'insekten',
        'vegetarisch-vegan',
        'schwein',
      ],
    },
    'nutritional-needs': {
      tags: [
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
      ],
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
  },
  'fur-hunde-gesundheit-lp': {
    funktion: {
      tags: [
        'gelenk',
        'haut-and-fell',
        'zeckensaison',
        'denta',
        'entspannung',
        'leber',
        'verdauung',
        'erkaltung',
      ],
    },
  },
  'fur-hunde-welpen-und-junghunde-lp': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'lachs',
        'lamm',
        'ente',
        'pferd',
        'kanguru',
        'wasserbuffel',
      ],
    },
  },
  'fur-hunde-getreidefreies-hundefutter': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'kanguru',
        'insekten',
        'vegetarisch-vegan',
        'schwein',
      ],
    },
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
        'bestimmte-erkrankung',
      ],
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
  },
  'fur-hunde-hypoallergenes-hundefutter-lp': {
    sorts: {
      tags: [
        'pferd',
        'lamm',
        'ente',
        'wild',
        'ziege',
        'insekt',
        'vegetarisch-vegan',
        'kanguru',
        'wasserbuffel',
      ],
    },
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
      ],
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-nahrungserganzung-lp': {
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
      ],
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
  },
  'fur-hunde-getreidefreies-hundefutter-ohne-getreide-lp': {
    sorts: {
      tags: [
        'huhn',
        'rind',
        'kalb',
        'pute',
        'pferd',
        'lamm',
        'wild',
        'geflugel',
        'thunfisch',
        'lachs',
        'forelle',
        'fisch',
      ],
    },
    desires: {
      tags: ['monoprotein', 'hypoallergen'],
      compare: 'AND',
    },
    age: {
      tags: ['welpe', 'adult', 'senior'],
    },
    line: {
      tags: ['classic', 'sensitiv', 'green'],
    },
  },
  'fur-hunde-senior-lp': {
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'kanguru',
        'kaninchen',
        'straus',
        'insekten',
        'vegetarisch-vegan',
        'schwein',
      ],
    },
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },

  'fur-katzen-nassfutter': {
    'nutritional-needs': {
      tags: [
        'pickyeatercat',
        'digestioncat',
        'overweightcat',
        'outdoorcat',
        'sterilizedcat',
      ],
    },
    line: {
      tags: [
        'saucen-menu',
        'pasteten',
        'mousse',
        'filet-menu',
        'silkycream',
        'juicyfilets',
        'puremeat',
        'meatypate',
        'vet',
        'limited',
      ],
    },
    age: {
      tags: ['kitten', 'adult', 'senior'],
    },
    sorts: {
      tags: [
        'huhn',
        'rind',
        'ente',
        'pute',
        'kalb',
        'wild',
        'lachs',
        'thunfisch',
        'kanguru',
        'kaninchen',
      ],
    },
  },
  'fur-hunde-chihuahua': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['rind-and-kalb', 'geflugel', 'lamm'],
    },
  },
  'fur-hunde-chihuahua-lp': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['rind-and-kalb', 'geflugel', 'lamm'],
    },
  },
  'fur-hunde-chihuahua-lp1': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['rind-and-kalb', 'geflugel', 'lamm'],
    },
  },
  'fur-hunde-spitz': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['rind-and-kalb', 'fisch', 'geflugel'],
    },
  },
  'fur-hunde-schaeferhund': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['rind-and-kalb', 'fisch', 'geflugel', 'insekten'],
    },
  },
  'fur-hunde-bulldogge': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: [
        'pferd',
        'ziege',
        'kanguru',
        'wild',
        'geflugel',
        'fisch',
        'insekten',
      ],
    },
  },
  'fur-hunde-bulldogge-lp': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: [
        'pferd',
        'ziege',
        'kanguru',
        'wild',
        'geflugel',
        'fisch',
        'insekten',
      ],
    },
  },
  'fur-hunde-bulldogge-lp1': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: [
        'pferd',
        'ziege',
        'kanguru',
        'wild',
        'geflugel',
        'fisch',
        'insekten',
      ],
    },
  },
  'fur-hunde-bulldogge-lp2': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: [
        'pferd',
        'ziege',
        'kanguru',
        'wild',
        'geflugel',
        'fisch',
        'insekten',
      ],
    },
  },
  'fur-hunde-mischling': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['rind-and-kalb', 'geflugel', 'wild'],
    },
  },
  'fur-hunde-retriever': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'lamm', 'rind-and-kalb', 'fisch', 'insekten'],
    },
  },
  'fur-hunde-terrier': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['rind-and-kalb', 'wild', 'fisch', 'geflugel', 'lamm'],
    },
  },
  'fur-hunde-terrier-lp': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['rind-and-kalb', 'wild', 'fisch', 'geflugel', 'lamm'],
    },
  },
  'fur-hunde-pudel': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['rind-and-kalb', 'fisch', 'geflugel', 'pferd', 'lamm', 'wild'],
    },
  },
  'fur-hunde-malteser': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'rind-and-kalb', 'lamm'],
    },
  },
  'fur-hunde-dackel': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'lamm', 'rind-and-kalb', 'fisch', 'insekten'],
    },
  },
  'fur-hunde-dackel-lp': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'lamm', 'rind-and-kalb', 'fisch', 'insekten'],
    },
  },
  'fur-hunde-dackel-lp1': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'lamm', 'rind-and-kalb', 'fisch', 'insekten'],
    },
  },
  'fur-hunde-huetehunde': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    sorts: {
      tags: ['geflugel', 'rind-and-kalb', 'wild', 'fisch', 'lamm'],
    },
  },
  'fur-katzen-trockenfutter': {
    sorts: {
      tags: ['huhn', 'lachs', 'geflugel', 'thunfisch'],
    },
    age: {
      tags: ['kitten', 'adult', 'senior'],
    },
    'nutritional-needs': {
      tags: [
        'pickyeatercat',
        'digestioncat',
        'overweightcat',
        'outdoorcat',
        'sterilizedcat',
      ],
    },
  },
  'fur-katzen-barf-frische-menus': {
    sorts: {
      tags: ['huhn', 'rind', 'wild', 'kanguru', 'hirsch'],
    },
    desires: {
      tags: ['getreidefrei', 'glutenfrei', 'hypoallergen'],
      compare: 'AND',
    },
    line: {
      tags: ['classic', 'sensitiv'],
    },
  },
  'fur-katzen-snacks': {
    'snack-type': {
      tags: [
        'creme-snack',
        'filet-snack',
        'suppe',
        'soft-snacks',
        'sensitiv',
        'pasten',
      ],
    },
    sorts: {
      tags: [
        'huhn',
        'ente',
        'pute',
        'wild',
        'lachs',
        'rind',
        'thunfisch',
        'hirsch',
      ],
    },
  },
  'fur-katzen-zubehor': {
    sorts: {
      tags: ['bowls-and-similars', 'toys', 'sleeping', 'other-accessories'],
    },
  },
  'fur-katzen-nutze-deinen-vorteil-lp': {
    sorts: {
      tags: [
        'huhn',
        'rind',
        'ente',
        'pute',
        'kalb',
        'wild',
        'lachs',
        'lamm',
        'thunfisch',
        'kanguru',
        'kaninchen',
      ],
    },
    desires: {
      tags: ['getreidefrei', 'glutenfrei', 'monoprotein', 'hypoallergen'],
      compare: 'AND',
    },
    age: {
      tags: ['kitten', 'adult', 'senior'],
    },
  },
  'fur-katzen-nassfutter-lp': {
    sorts: {
      tags: [
        'huhn',
        'rind',
        'ente',
        'pute',
        'kalb',
        'wild',
        'lachs',
        'lamm',
        'thunfisch',
        'kanguru',
        'kaninchen',
      ],
    },
    desires: {
      tags: ['getreidefrei', 'glutenfrei', 'hypoallergen'],
      compare: 'AND',
    },
    age: {
      tags: ['kitten', 'adult', 'senior'],
    },
    line: {
      tags: [
        'classic',
        'sensitiv',
        'pure-meat',
        'feinschmecker-pate',
        'saucen-menu',
        'genieser-filets',
        'filet-menu',
        'mousse',
        'vet',
        'limited',
      ],
    },
  },
  'fur-katzen-trockenfutter-lp': {
    sorts: {
      tags: ['huhn', 'lachs', 'geflugel', 'truthahn'],
    },
    desires: {
      tags: ['getreidefrei', 'glutenfrei', 'hypoallergen'],
      compare: 'AND',
    },
    age: {
      tags: ['kitten', 'adult', 'senior'],
    },
    line: {
      tags: ['classic', 'sensitiv', 'extra-meat'],
    },
  },
  'fur-katzen-barf-frische-menus-lp': {
    sorts: {
      tags: ['huhn', 'rind', 'wild', 'kanguru', 'hirsch'],
    },
    desires: {
      tags: ['getreidefrei', 'glutenfrei', 'hypoallergen'],
      compare: 'AND',
    },
    line: {
      tags: ['classic', 'sensitiv'],
    },
  },
  'fur-katzen-kitten-und-junge-katze-lp': {
    sorts: {
      tags: [
        'huhn',
        'rind',
        'ente',
        'pute',
        'kalb',
        'wild',
        'lachs',
        'lamm',
        'thunfisch',
        'kanguru',
        'kaninchen',
      ],
    },
    desires: {
      tags: ['getreidefrei', 'glutenfrei', 'hypoallergen'],
      compare: 'AND',
    },
    line: {
      tags: [
        'classic',
        'sensitiv',
        'pure-meat',
        'feinschmecker-pate',
        'saucen-menu',
        'genieser-filets',
        'filet-menu',
        'mousse',
        'vet',
        'limited',
      ],
    },
  },
  'fur-katzen-alle-konsistenzen-lp': {
    sorts: {
      tags: [
        'huhn',
        'rind',
        'ente',
        'pute',
        'kalb',
        'wild',
        'lachs',
        'lamm',
        'thunfisch',
        'kanguru',
        'kaninchen',
      ],
    },
    desires: {
      tags: ['getreidefrei', 'glutenfrei', 'hypoallergen'],
      compare: 'AND',
    },
    age: {
      tags: ['kitten', 'adult', 'senior'],
    },
    line: {
      tags: [
        'classic',
        'sensitiv',
        'pure-meat',
        'feinschmecker-pate',
        'saucen-menu',
        'genieser-filets',
        'filet-menu',
        'mousse',
        'vet',
        'limited',
      ],
    },
  },
  [Handles.allProductsForCat]: {
    'hero-filter': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    line: {
      tags: [
        'saucen-menu',
        'pasteten',
        'mousse',
        'filet-menu',
        'silkycream',
        'juicyfilets',
        'puremeat',
        'meatypate',
        'vet',
        'limited',
      ],
    },
    'nutritional-needs': {
      tags: [
        'pickyeatercat',
        'digestioncat',
        'overweightcat',
        'outdoorcat',
        'sterilizedcat',
      ],
    },
    age: {
      tags: ['kitten', 'adult', 'senior'],
    },
    sorts: {
      tags: [
        'huhn',
        'rind',
        'ente',
        'pute',
        'kalb',
        'wild',
        'lachs',
        'thunfisch',
        'kanguru',
        'kaninchen',
      ],
    },
  },
  'fur-katzen-alle-fleischsorten-lp': {
    'hero-filter': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    line: {
      tags: ['saucen-menu', 'pasteten', 'mousse', 'filet-menu'],
    },
    'nutritional-needs': {
      tags: [
        'pickyeatercat',
        'digestioncat',
        'overweightcat',
        'outdoorcat',
        'sterilizedcat',
      ],
    },
    age: {
      tags: ['kitten', 'adult', 'senior'],
    },
    sorts: {
      tags: [
        'huhn',
        'rind',
        'ente',
        'pute',
        'kalb',
        'wild',
        'lachs',
        'thunfisch',
        'kanguru',
        'kaninchen',
      ],
    },
  },
  'fur-katzen-hypoallergenes-katzenfutter-lp': {
    sorts: {
      tags: ['kanguru', 'hirsch', 'ente', 'wild'],
    },
    age: {
      tags: ['kitten', 'adult', 'senior'],
    },
  },
  'fur-katzen-getreidefreies-katzenfutter-ohne-getreide-lp': {
    sorts: {
      tags: [
        'huhn',
        'rind',
        'ente',
        'pute',
        'kalb',
        'wild',
        'lachs',
        'lamm',
        'thunfisch',
        'kanguru',
        'kaninchen',
      ],
    },
    desires: {
      tags: ['glutenfrei', 'glutenfrei', 'hypoallergen'],
    },
    age: {
      tags: ['kitten', 'adult', 'senior'],
    },
  },
  probierpakete: {
    species: {
      tags: ['fur-hunde', 'fur-katzen'],
    },
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'ziege',
        'kanguru',
        'wasserbuffel',
        'insekten',
        'vegetarisch-vegan',
      ],
    },
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
      ],
    },
    age: {
      tags: ['kitten', 'welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
    // maybe it's better to leave it as it is for cat page
    desires: {
      tags: ['getreidefrei', 'glutenfrei', 'monoprotein', 'hypoallergen'],
      compare: 'AND',
    },
    line: {
      tags: ['classic', 'sensitiv', 'green'],
    },
  },
  'fur-katzen-wahlerisch': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    line: {
      tags: [
        'saucen-menu',
        'pasteten',
        'mousse',
        'filet-menu',
        'silkycream',
        'juicyfilets',
        'puremeat',
        'meatypate',
        'vet',
        'limited',
      ],
    },
    sorts: {
      tags: ['huhn', 'wild', 'thunfisch', 'pute', 'lachs', 'geflugel', 'rind'],
    },
  },
  'fur-katzen-wahlerisch-lp': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    line: {
      tags: ['saucen-menu', 'pasteten', 'mousse', 'filet-menu'],
    },
    sorts: {
      tags: ['huhn', 'wild', 'thunfisch', 'pute', 'lachs', 'geflugel', 'rind'],
    },
  },
  'fur-katzen-wahlerisch-lp1': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    line: {
      tags: ['saucen-menu', 'pasteten', 'mousse', 'filet-menu'],
    },
    sorts: {
      tags: ['huhn', 'wild', 'thunfisch', 'pute', 'lachs', 'geflugel', 'rind'],
    },
  },
  'fur-katzen-verdauungsprobleme': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    line: {
      tags: [
        'saucen-menu',
        'pasteten',
        'mousse',
        'filet-menu',
        'silkycream',
        'juicyfilets',
        'puremeat',
        'meatypate',
        'vet',
        'limited',
      ],
    },
    sorts: {
      tags: ['huhn', 'pute', 'kanguru', 'lachs', 'thunfisch', 'ente', 'rind'],
    },
  },
  'fur-katzen-ubergewicht': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    line: {
      tags: [
        'saucen-menu',
        'pasteten',
        'mousse',
        'filet-menu',
        'silkycream',
        'juicyfilets',
        'puremeat',
        'meatypate',
        'vet',
        'limited',
      ],
    },
    sorts: {
      tags: ['huhn', 'pute', 'lachs', 'thunfisch', 'ente', 'rind'],
    },
  },
  'fur-katzen-sterilisiert': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    line: {
      tags: [
        'saucen-menu',
        'pasteten',
        'mousse',
        'filet-menu',
        'silkycream',
        'juicyfilets',
        'puremeat',
        'meatypate',
        'vet',
        'limited',
      ],
    },
    sorts: {
      tags: ['huhn', 'pute', 'rind', 'thunfisch'],
    },
  },
  'fur-katzen-freiganger': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    line: {
      tags: [
        'saucen-menu',
        'pasteten',
        'mousse',
        'filet-menu',
        'silkycream',
        'juicyfilets',
        'puremeat',
        'meatypate',
        'vet',
        'limited',
      ],
    },
    sorts: {
      tags: ['lachs', 'huhn', 'rind', 'wild', 'ente', 'geflugel'],
    },
  },
  'fur-katzen-kitten-und-junge-katze': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    line: {
      tags: [
        'saucen-menu',
        'pasteten',
        'mousse',
        'filet-menu',
        'silkycream',
        'juicyfilets',
        'puremeat',
        'meatypate',
        'vet',
        'limited',
      ],
    },
    sorts: {
      tags: ['lachs', 'huhn', 'rind', 'pute', 'kalb'],
    },
  },

  'fur-katzen-senior': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    line: {
      tags: [
        'saucen-menu',
        'pasteten',
        'mousse',
        'filet-menu',
        'silkycream',
        'juicyfilets',
        'puremeat',
        'meatypate',
        'vet',
        'limited',
      ],
    },
    sorts: {
      tags: [
        'pute',
        'ente',
        'huhn',
        'kanguru',
        'rind',
        'truthahn',
        'lachs',
        'thunfisch',
        'kalb',
      ],
    },
  },
  'fur-katzen-senior-lp': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'snacks'],
    },
    line: {
      tags: ['saucen-menu', 'pasteten', 'mousse', 'filet-menu'],
    },
    sorts: {
      tags: [
        'pute',
        'ente',
        'huhn',
        'kanguru',
        'rind',
        'truthahn',
        'lachs',
        'thunfisch',
        'kalb',
      ],
    },
  },

  'tawkr-assortment': {
    species: {
      tags: ['fur-hunde', 'fur-katzen'],
    },
  },
  'fur-hunde-und-katzen-nassfutter': {
    species: {
      tags: ['fur-hunde', 'fur-katzen'],
    },
    sorts: {
      tags: [
        'huhn',
        'rind',
        'ente',
        'pute',
        'kalb',
        'wild',
        'lachs',
        'pferd',
        'lamm',
        'vegan',
        'ziege',
        'hirsch',
        'kanguru',
        'kaninchen',
        'thunfisch',
      ],
    },
    desires: {
      tags: [
        'getreidefrei',
        'glutenfrei',
        'hypoallergen',
        'monoprotein',
        'low-energy',
        'high-energy',
      ],
    },
  },
  'fur-hunde-und-katzen-trockenfutter': {
    species: {
      tags: ['fur-hunde', 'fur-katzen'],
    },
    sorts: {
      tags: [
        'huhn',
        'rind',
        'ente',
        'pute',
        'kalb',
        'wild',
        'lachs',
        'pferd',
        'lamm',
        'vegan',
        'ziege',
        'hirsch',
        'kanguru',
        'kaninchen',
        'thunfisch',
      ],
    },
    desires: {
      tags: [
        'getreidefrei',
        'glutenfrei',
        'hypoallergen',
        'monoprotein',
        'low-energy',
        'high-energy',
      ],
    },
  },
  'fur-hunde-senior': {
    'feed-type': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'kanguru',
        'kaninchen',
        'straus',
        'insekten',
        'vegetarisch-vegan',
        'schwein',
      ],
    },
  },
  'fur-hunde-probierpakete': {
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'ziege',
        'kanguru',
        'kaninchen',
        'straus',
        'schwein',
        'insekten',
        'vegetarisch-vegan',
      ],
    },
    'nutritional-needs': {
      tags: [
        'sehr-wahlerisch',
        'verdauungsprobleme',
        'unvertraglichkeiten-allergien',
        'haut-and-fellprobleme',
        'bestimmte-erkrankung',
      ],
      compare: 'AND',
    },
    age: {
      tags: ['welpe', 'adult-1-6-jahre', 'senior-7-jahre'],
    },
  },
  'fur-hunde-adult': {
    sorts: {
      tags: [
        'geflugel',
        'rind-and-kalb',
        'wild',
        'fisch',
        'lamm',
        'pferd',
        'ziege',
        'kanguru',
        'wasserbuffel',
        'insekten',
        'vegetarisch-vegan',
      ],
    },
    'hero-filter': {
      tags: ['nassfutter', 'trockenfutter', 'barf', 'snacks'],
    },
  },
  'fur-hunde-alle-produkte': {
    ...ALL_AVAILABLE_FILTERS,
  },
  'fur-katzen-alle-produkte': {
    ...ALL_AVAILABLE_FILTERS,
  },
};

export default FILTER_CONFIG;
