import cn from 'classnames';

interface Props {
  className?: string;
  rounded?: boolean;
  type?: 'General' | 'Limited' | 'Discount' | 'Bestseller';
}

/**
 * Badge component
 */
const Badge: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  rounded,
  type = 'General',
}) => {
  return (
    <div
      className={cn(
        'h-auto shrink-0 items-center justify-center rounded px-1.5 py-0.5 text-center text-sm',
        type === 'Discount' &&
          'bg-badge-discount font-medium text-text-dark-bg-contrast-white',
        type === 'Limited' && 'bg-badge-new text-primary-main',
        type === 'Bestseller' && 'bg-badge-bestseller text-primary-main',
        type === 'General' && 'bg-badge-generic text-text-primary',
        rounded && 'rounded-full',
        className
      )}
    >
      <span data-test="ribbon" className="block">
        {children}
      </span>
    </div>
  );
};
export default Badge;
