import { ShopId } from './shop';

export const FeatureFlag = {
  cartCrossSell: 'cartCrossSell',
  crossSellCheckout: 'crossSellCheckout',
  crossSellPdp: 'crossSellPdp',
  fube: 'fube',
  magazin: 'magazin',
  nutritionPlan: 'nutritionPlan',
  segmentation: 'segmentation',
  subCrossSell: 'subCrossSell',
  onDemandDelivery: 'onDemandDelivery',
  cartCrossSellPopUp: 'cartCrossSellPopUp',
  dynamicUxTest: 'dynamicUxTest',
  smartCollectionEndpoint: 'smartCollectionEndpoint',
  cookieBot: 'cookieBot',
  aviabilityBarf: 'aviabilityBarf',
};

export type FeatureFlag = (typeof FeatureFlag)[keyof typeof FeatureFlag];

const featureFlagCH: Record<FeatureFlag, boolean> = {
  [FeatureFlag.cartCrossSell]: true,
  [FeatureFlag.crossSellCheckout]: false,
  [FeatureFlag.crossSellPdp]: false,
  [FeatureFlag.crossSellPdp]: false,
  [FeatureFlag.cartCrossSellPopUp]: false,
  [FeatureFlag.fube]: false,
  [FeatureFlag.magazin]: false,
  [FeatureFlag.nutritionPlan]: false,
  [FeatureFlag.segmentation]: true,
  [FeatureFlag.subCrossSell]: false,
  [FeatureFlag.onDemandDelivery]: false,
  [FeatureFlag.cookieBot]: false,
  [FeatureFlag.aviabilityBarf]: false,
};

const flag = {
  [ShopId.CH]: featureFlagCH,
};

export const getFeatureFlag = (arg: FeatureFlag): boolean => {
  return !flag[process.env.SHOP_ID] || flag[process.env.SHOP_ID][arg] !== false;
};
