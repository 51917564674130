import { ShopId } from 'constants/shop';
import { CatGeneralCdpHandle } from 'models/collection/constants/cat-general-cdp';
import { DogGeneralCdpHandle } from 'models/collection/constants/dog-general-cdp';

/**
 * Due to data availability, CH shop ignores dog general pages too
 */
const HANDLES = [
  ...Object.values(CatGeneralCdpHandle),
  ...(process.env.SHOP_ID === ShopId.CH
    ? Object.values(DogGeneralCdpHandle)
    : []),
] as Array<string>;

/**
 * Quick fix for cat personalization title.
 * we ignore specific food type cdp
 */
const shouldIgnoreSubtitle = ({ handle }: { handle?: string }) => {
  if (!handle) return false;

  return HANDLES.includes(handle);
};

export default shouldIgnoreSubtitle;
