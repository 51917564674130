import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocalStorage, useLockBodyScroll } from 'react-use';
import { useSearchStore } from 'stores/search';
import { gtm, selectElement, unselectElement } from 'tracking';
import { EventName, trackEvent } from 'utils/tracking';
import IconArrowLeft from '../../../assets/icons/arrow-left.svg';
import IconCross from '../../../assets/icons/cross.svg';
import IconLastSearches from '../../../assets/icons/last-searches.svg';
import IconMagnifier from '../../../assets/icons/magnifier.svg';
import { usePetContext } from '../../../contexts/pet';
import useOnClickOutside from '../../../hooks/common/use-on-click-outside';

type searchHistory = {
  searchHistoryContainer?: Array<string>;
};

interface Props {
  setSearchOverlayClose?: (arg0: boolean) => void;
}

/**
 * Search component for the header. It is a controlled component that uses the search store to manage the search term.
 */
const Search: React.FC<Props> = ({ setSearchOverlayClose }) => {
  const router = useRouter();
  const { current } = usePetContext();
  const intl = useIntl();
  const [focused, setFocused] = React.useState(false);
  const [visible, setVisibility] = React.useState(false);
  const { searchedTerm, searchedUrlTerm, setSearchedTerm, setSearchedUrlTerm } =
    useSearchStore();
  const searchRef = React.useRef<HTMLFormElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [searchHistory, setSearchHistory] = useLocalStorage<searchHistory>(
    'pd:search-history',
    undefined
  );

  useEffect(() => {
    if (router.query.term) {
      setSearchedUrlTerm(searchedTerm);
    } else {
      setSearchedUrlTerm('');
    }
  }, [router.query.term, searchedTerm, setSearchedUrlTerm]);

  useLockBodyScroll(focused);

  // Add the updated setInputOpen function here
  const setInputOpen = useCallback(
    (isOpen = true) => {
      setFocused(isOpen);
      setVisibility(isOpen);
      setSearchOverlayClose && setSearchOverlayClose(!isOpen);
      if (isOpen && inputRef.current && window.matchMedia('(max-width: 1024px)').matches) {
        inputRef.current.focus();
      }
    },
    [setFocused, setVisibility, setSearchOverlayClose, inputRef]
  );

  useOnClickOutside(searchRef, () => {
    setInputOpen(false);
  });

  const deleteHistory = (): void => {
    if (searchHistory) {
      searchHistory.searchHistoryContainer = [];
      setSearchHistory(searchHistory);
    }
  };

  const addNewSearch = (newSearch: string): void => {
    if (searchHistory?.searchHistoryContainer?.includes(newSearch)) {
      searchHistory.searchHistoryContainer =
        searchHistory.searchHistoryContainer.filter(
          (term) => term !== newSearch
        );
      searchHistory.searchHistoryContainer.unshift(newSearch);
    } else {
      searchHistory?.searchHistoryContainer?.unshift(newSearch);
    }
    setSearchHistory(searchHistory);
  };

  const cleanInputField = (): void => {
    if (inputRef.current) {
      setSearchedUrlTerm('');
      inputRef.current.focus();
    }
  };

  React.useEffect(() => {
    if (!searchHistory) setSearchHistory({ searchHistoryContainer: [] });
  }, [setSearchHistory, searchHistory]);

  React.useEffect(() => {
    if (visible === true) {
      document.body.classList.add('overflow-hidden', 'lg:overflow-auto');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => document.body.classList.remove('overflow-hidden');
  }, [visible]);

  React.useEffect(() => {
    setInputOpen(false);
  }, [router.asPath, setInputOpen]);

  const removeFocus = (): void => {
    document.activeElement && (document.activeElement as HTMLElement).blur();
  };

  return (
    <>
      <form
        data-search
        // ↓↓↓ needed to display the 'search' button on the mobile keyboard
        action="."
        role="search"
        data-test="search-form"
        className={cn(
          {
            'bg-other-white lg:bg-primary-main fixed left-0 top-0 p-2 lg:relative lg:h-full lg:w-full lg:p-0':
              focused,
          },
          focused
            ? 'h-screen w-screen'
            : 'my-auto flex h-9 w-full items-center lg:h-full'
        )}
        ref={searchRef}
        onSubmit={(event) => {
          event.preventDefault();
          removeFocus();
          setInputOpen(false);
          setSearchedTerm(searchedUrlTerm);
          addNewSearch(searchedUrlTerm);

          router.push({
            pathname: '/search',
            query: {
              term: searchedUrlTerm.toLowerCase(),
              ...(current !== false && {
                category: current,
              }),
            },
          });
        }}
      >
        <div
          className={cn(
            'relative z-search h-full w-full items-center justify-center'
          )}
        >
          {/* magnifier */}
          <div
            className={cn(
              focused
                ? 'absolute h-12 bg-transparent'
                : 'lg:bottom-0 lg:left-0',
              'text-grey-700-60 hover:text-grey-700-80 z-search flex h-9 flex-col items-center justify-between leading-none focus:outline-none lg:h-12 lg:w-12'
            )}
          >
            <button
              type="button"
              data-test="search-button"
              className={cn(
                !focused && 'flex-col justify-center',
                'relative flex h-full w-full items-center justify-center'
              )}
              onClick={() => {
                setInputOpen(!focused);
                focused
                  ? gtm(
                      unselectElement({
                        element: ['Header', 'Search'],
                      })
                    )
                  : gtm(
                      selectElement({
                        element: ['Header', 'Search'],
                      })
                    );
              }}
            >
              {focused ? (
                <>
                  <IconArrowLeft
                    strokeWidth="2"
                    className="h-6 pr-4 mt-3 bg-transparent text-primary-main lg:hidden"
                  />
                  <IconMagnifier className="hidden h-6 text-text-light-bg-secondary lg:block" />
                </>
              ) : (
                <>
                  <IconMagnifier className="h-6 text-primary-main lg:text-text-light-bg-secondary shrink-0 lg:h-7" />
                  <span className="font-medium leading-4 text-primary-main text-xxs lg:hidden">
                    <FormattedMessage id="header:search" />
                  </span>
                </>
              )}
            </button>
          </div>

          <div
            className={cn(
              focused ? 'top-0 z-modal py-0' : 'pr-2',
              'flex w-full',
              'pointer-events-none h-full w-full lg:static lg:w-auto lg:p-0'
            )}
          >
            {/* search field */}
            <div
              className={cn(
                'absolute bottom-0 top-0 flex w-full lg:items-center',
                !focused && 'items-center',
                !focused && 'h-0 overflow-hidden lg:h-full lg:overflow-auto',
                focused &&
                  ' lg:border-primary-main lg:rounded-t-2xl lg:border-l lg:border-r',
                {
                  'bg-other-white':
                    focused &&
                    searchHistory?.searchHistoryContainer?.length !== 0,
                }
              )}
            >
              <input
                type="search"
                required
                ref={inputRef}
                aria-label="Futter suchen"
                data-test="search-input"
                className={cn(
                  'placeholder-grey-300 pointer-events-auto flex-grow lg:h-full',
                  'border-other-white bg-grey-100 lg:bg-other-white h-12 rounded-xl focus:outline-none lg:h-10 lg:rounded-lg lg:border ',
                  focused
                    ? 'lg:border-primary-main ml-8  rounded-2xl pl-3 lg:ml-0 lg:rounded-2xl lg:border lg:pl-12'
                    : 'rounded-sm pl-12'
                )}
                placeholder={intl.formatMessage({
                  id: 'header:search:placeholder',
                })}
                value={searchedUrlTerm}
                onFocus={() => {
                  trackEvent(EventName.SearchStart);
                  setInputOpen();
                }}
                onClick={() => {
                  setInputOpen();
                }}
                onChange={(event) =>
                  setSearchedUrlTerm(event.target.value as string)
                }
              />
              {focused && searchedUrlTerm.length > 0 && (
                <button
                  type="button"
                  onClick={() => {
                    cleanInputField();
                  }}
                  className="absolute z-20 flex items-center justify-center mt-2 bg-transparent pointer-events-auto right-2 h-9 w-9 lg:bottom-auto lg:top-auto "
                >
                  <IconCross className="w-3 text-primary-main lg:w-5" />
                </button>
              )}
            </div>
            {focused && (
              <div
                className={cn(
                  {
                    'lg:hidden':
                      searchHistory?.searchHistoryContainer?.length === 0,
                  },
                  'bg-other-white lg:border-primary-main pointer-events-auto absolute left-0  top-14 z-modal w-full overflow-auto p-4 lg:top-10 lg:mt-2 lg:h-auto lg:rounded-b-2xl lg:border lg:border-t-0 '
                )}
              >
                <div
                  className={cn(
                    {
                      hidden:
                        searchHistory?.searchHistoryContainer?.length === 0,
                    },
                    'mt-2 flex items-center justify-between font-black'
                  )}
                >
                  <p className="text-lg text-text-light-bg-secondary lg:text-base">
                    <FormattedMessage id="header:search:last-queries" />
                  </p>
                  <button
                    type="button"
                    className="text-sm font-black text-grey-950 lg:text-xs"
                    onClick={() => {
                      deleteHistory();
                    }}
                  >
                    <FormattedMessage id="header:search:delete" />
                  </button>
                </div>

                {searchHistory !== null && (
                  <div className="mt-2">
                    {searchHistory?.searchHistoryContainer
                      ?.slice(0, 8)
                      .map((term: string) => {
                        return (
                          <Link
                            href={`/search?term=${term.toLowerCase()}&category=${
                              current === false ? 'both' : current
                            }`}
                            key={term}
                          >
                            <a
                              onClick={() => {
                                setInputOpen(false);
                                setSearchedTerm(term);
                                setSearchedUrlTerm(term);
                              }}
                            >
                              <div className="flex mb-1 text-grey-950">
                                <div>
                                  <IconLastSearches className="w-5 mr-3 lg:mt-1 lg:w-4" />
                                </div>
                                <p className="text-base">{term}</p>
                              </div>
                            </a>
                          </Link>
                        );
                      })}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </form>
      {focused && (
        <div
          className={cn(
            'bg-other-backdrop-overlay fixed left-0 top-0 z-search-backdrop hidden h-screen w-screen lg:block'
          )}
        ></div>
      )}
    </>
  );
};

export default Search;